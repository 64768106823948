import { AntRuleValidator } from '@/types/ant-design';
import { LenValidatorType } from '@/types/validator.type';

/** Ant Design custom validators */
export class Validator {
  /** Required field */
  public static isRequired(message?: string) {
    return (_rule: AntRuleValidator, value: string | null) => {
      return value === '' || value === null || value === undefined
        ? Promise.reject(message || 'Required field')
        : Promise.resolve();
    };
  }

  /** Validate length value (equals, max, min) */
  public static len(type: LenValidatorType, length: number, message?: string) {
    return (_rule: AntRuleValidator, value: string) => {
      /** Condition to evaluate */
      let condition;
      /** Default message */
      let msg = '';
      /** Convert to string */
      const strValue = String(value);

      /** Generate condition according type */
      switch (type) {
        case 'equals':
          condition = strValue.length === length;
          msg = 'Length';
          break;
        case 'min':
          condition = strValue.length >= length;
          msg = 'Min length';
          break;
        case 'max':
          condition = strValue.length <= length;
          msg = 'Max length';
          break;
      }

      return !condition
        ? Promise.reject(message || `${msg} must be ${length}`)
        : Promise.resolve();
    };
  }

  /** Validate length value (equals, max, min) */
  public static range(form: any, message?: string) {
    return (_rule: AntRuleValidator, value: string) => {
      const tmpValue: any = value;

      let isValidate = true;
      let msg = '';
      let percentage = 0;

      /** Generate isValidte according type */
      switch (form.initialPayment.modo) {
        case '%':
          percentage = (5 / form.totalPayment) * 100;
          if (
            form.initialPayment.value !== null &&
            form.initialPayment.value !== '' &&
            form.initialPayment.value !== 0 &&
            (tmpValue < percentage || tmpValue >= 100)
          ) {
            isValidate = false;
            msg =
              'Percentage must be equal to or greater than ' +
              percentage.toFixed(1) +
              '% and less than 100%';
          }
          break;
        case '$':
          if (
            form.initialPayment.value !== null &&
            form.initialPayment.value !== '' &&
            form.initialPayment.value !== 0 &&
            (tmpValue < 5 || tmpValue >= form.totalPayment)
          ) {
            isValidate = false;
            msg =
              'Amount must be equal to or greater than $5 and less than $' +
              form.totalPayment;
          }
          break;
      }

      return !isValidate ? Promise.reject(message || msg) : Promise.resolve();
    };
  }

  /** Validate length value (equals, max, min) */
  public static rangeTotal(form: any, message?: string) {
    return () => {
      let isValidate = true;
      let msg = '';

      if (!form.totalPayment) {
        isValidate = false;
        msg = 'Required field';
      } else if (form.totalPayment < 5) {
        isValidate = false;
        msg = 'Amount less than 5 dollars';
      }

      return !isValidate ? Promise.reject(message || msg) : Promise.resolve();
    };
  }

  /** Validate last 4 numbers of bank account number
   * E-Check form trust account lex charge */
  public static lastFourNumbers(validateString: any) {
    return (_rule: AntRuleValidator, value: string) => {
      if (validateString.value !== '') {
        const myLastFourNumbers = value.substr(value.length - 4);
        const primaPayTrustAccountNumber = validateString.value.substr(
          validateString.value.length - 4,
        );

        return myLastFourNumbers !== primaPayTrustAccountNumber
          ? Promise.reject(`Must be ended ${validateString.value}`)
          : Promise.resolve();
      }

      return Promise.resolve();
    };
  }
}
