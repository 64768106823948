<template>
  <AForm layout="vertical">
    <ARow :gutter="12">
      <ACol :span="24">
        <AFormItem label="Saved Cards" name="savedCards">
          <ASelect
            v-model:value="savedPaymentMethod"
            @change="setSavedPayment"
            :disabled="paymentMethods.length === 0"
          >
            <ASelectOption value="managePaymentMethods" @click="showModal">
              Manage Payment Methods
            </ASelectOption>
            <ASelectOption value="none"> None </ASelectOption>
            <ASelectOption
              v-for="(paymentMethod, paymentMethodIndex) in paymentMethods"
              :key="paymentMethodIndex"
              :value="paymentMethod"
            >
              {{ paymentMethod.nickname }}
            </ASelectOption>
          </ASelect>
        </AFormItem>
      </ACol>

      <img
        v-if="savedPaymentMethod.id"
        :src="require(`@/assets/images/cards/${cardBrand}.png`)"
        :alt="`${cardBrand}.png`"
        class="card-brand"
        :style="
          onlyForm
            ? 'margin-right: 0.5rem;margin-top: 0.5rem;'
            : 'margin-top: 3.5rem;'
        "
      />

      <ACol :span="8">
        <AFormItem
          label="First Name"
          name="firstName"
          v-bind="validateInfos['firstName']"
        >
          <AInput
            v-model:value="form.firstName"
            name="firstName"
            placeholder="First Name"
            style="width: 100%"
            :disabled="disableFields"
          />
        </AFormItem>
      </ACol>

      <ACol :span="8">
        <AFormItem
          label="Last Name"
          name="lastName"
          v-bind="validateInfos['lastName']"
        >
          <AInput
            v-model:value="form.lastName"
            name="lastName"
            placeholder="Last Name"
            style="width: 100%"
            :disabled="disableFields"
          />
        </AFormItem>
      </ACol>

      <ACol :span="8">
        <AFormItem label="Email" name="email" v-bind="validateInfos['email']">
          <AInput
            v-model:value="form.email"
            name="email"
            placeholder="Email"
            style="width: 100%"
            :disabled="disableFields || disabledFieldEmail"
          />
        </AFormItem>
      </ACol>

      <ACol v-if="savedPaymentMethod.id" :span="10">
        <AFormItem label="Card Number" name="cardNumber">
          <AInput
            v-model:value="form.cardNumber"
            name="cardNumber"
            placeholder="Card Number"
            style="width: 100%"
            :disabled="disableFields"
          />
        </AFormItem>
      </ACol>
      <ACol
        v-else
        :span="10"
        style="width: 100%; padding-bottom: 8px; margin-bottom: 20px"
      >
        <label class="CreditCard__label" for="credit_card_field"
          >Card Number</label
        >
        <div id="fattjs-number" class="primapay-field">
          <img
            :src="require(`@/assets/images/cards/${cardBrand}.png`)"
            :alt="`${cardBrand}.png`"
            class="card-brand"
          />
        </div>
        <div
          v-if="errorValidateCardMessage !== ''"
          style="margin-top: -5px; color: #f5222d"
          class="ant-form-explain"
        >
          {{ errorValidateCardMessage }}
        </div>
      </ACol>

      <ACol v-if="savedPaymentMethod.id" :span="6">
        <AFormItem label="CVV" name="cvv">
          <AInput
            v-model:value="form.cvv"
            name="cvv"
            placeholder="CVV"
            style="width: 100%"
            :disabled="disableFields"
          />
        </AFormItem>
      </ACol>
      <ACol v-else :span="6">
        <label class="CreditCard__label" for="cvv_field">CVV</label>
        <div id="fattjs-cvv" class="primapay-field"></div>
        <div
          v-if="errorValidateCvvMessage !== ''"
          style="margin-top: -5px; color: #f5222d"
          class="ant-form-explain"
        >
          {{ errorValidateCvvMessage }}
        </div>
      </ACol>

      <ACol :span="8">
        <AFormItem label="Expiration Date" v-bind="validateInfos['exp_date']">
          <AMonthPicker
            v-model:value="form.exp_date"
            :disabled-date="disabledDate"
            format="MM/YYYY"
            placeholder="MM/YYYY"
            style="width: 100%"
            :disabled="disableFields"
          />
        </AFormItem>
      </ACol>

      <ACol :span="8">
        <AFormItem label="Phone" name="phone" v-bind="validateInfos['phone']">
          <input
            type="text"
            v-maska="'(###) ### ####'"
            v-model="form.phone"
            class="ant-input"
            placeholder="Phone"
            :disabled="disableFields || disabledFieldPhone"
          />
        </AFormItem>
      </ACol>

      <ACol :span="8">
        <AFormItem
          label="Address"
          name="address"
          v-bind="validateInfos['address1']"
        >
          <AInput
            v-model:value="form.address1"
            name="address"
            placeholder="Address"
            style="width: 100%"
            :disabled="disableFields"
          />
        </AFormItem>
      </ACol>

      <ACol :span="8">
        <AFormItem label="City" name="city" v-bind="validateInfos['city']">
          <AInput
            v-model:value="form.city"
            name="city"
            placeholder="City"
            style="width: 100%"
            :disabled="disableFields"
          />
        </AFormItem>
      </ACol>

      <ACol :span="8">
        <AFormItem
          label="State Code"
          name="state"
          v-bind="validateInfos['state']"
        >
          <ASelect
            v-model:value="form.state"
            show-search
            :disabled="disableFields"
            placeholder="Choose state code"
            :filter-option="filterOption"
          >
            <ASelectOption
              v-for="(state, stateIndex) in states"
              :key="stateIndex"
              :value="state"
            >
              {{ state }}
            </ASelectOption>
          </ASelect>
        </AFormItem>
      </ACol>

      <ACol :span="8">
        <AFormItem
          label="Postal Code"
          name="postal_code"
          v-bind="validateInfos['postal_code']"
        >
          <AInputNumber
            v-model:value="form.postal_code"
            type="number"
            :min="0"
            name="postal_code"
            placeholder="Postal Code"
            style="width: 100%"
            :disabled="disableFields"
          />
        </AFormItem>
      </ACol>

      <ACol :span="8">
        <AFormItem
          label="Country Code"
          name="country"
          v-bind="validateInfos['country']"
        >
          <AInput
            v-model:value="form.country"
            name="country"
            placeholder="Country"
            style="width: 100%"
            :disabled="true"
          />
        </AFormItem>
      </ACol>
    </ARow>
  </AForm>

  <AModal
    v-model:visible="visible"
    title="Manage Payment Methods"
    width="800px"
    bodyStyle="height:400px;overflow-y:scroll;"
  >
    <AList item-layout="horizontal" :data-source="paymentMethods">
      <template #renderItem="{ item }">
        <AListItem>
          <template #actions>
            <AButton
              v-if="item.confirmDelete"
              shape="round"
              size="small"
              @click="item.confirmDelete = false"
            >
              Cancel
            </AButton>
            <AButton
              v-if="item.confirmDelete"
              shape="round"
              size="small"
              type="danger"
              :loading="loading"
              @click="deletePaymentMethod(item)"
            >
              Confirm
            </AButton>
            <a v-else style="color: red" @click="item.confirmDelete = true"
              >Delete</a
            >
          </template>
          <AListItemMeta>
            <template #title>
              <b>{{ item.nickname }}</b>
              <div style="margin-left: 20px">
                <b>Number:</b> {{ setCustomMethodNumber(item.cardLastFour) }}
              </div>
              <div style="margin-left: 20px">
                <b>Exp Date:</b> {{ item.cardExpDatetime }}
              </div>
            </template>
          </AListItemMeta>
        </AListItem>
      </template>
    </AList>
    <template #footer>
      <AButton key="back" type="primary" @click="handleClose"> Close </AButton>
    </template>
  </AModal>

  <slot :validateFn="validate" :primaPayRequestFn="primaPayRequest" />
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue';
import { useForm } from '@ant-design-vue/use';

import { PaymentForm } from '@/types/payment-form.type';
import { Validator } from '@/utils/ant-custom-validators';
import moment from 'moment';
import { useGlobalProps } from '@/composables';
import { IntegrationsService } from '@/services';
import { message } from 'ant-design-vue';

export default defineComponent({
  props: {
    contactId: {
      type: Number,
    },
    primaPayAccountId: {
      type: String,
    },
    onlyForm: {
      type: Boolean,
    },
  },
  setup(props) {
    const { setLoading, states } = useGlobalProps();
    const form = reactive<PaymentForm['credit_card']>({
      firstName: '',
      lastName: '',
      email: '',
      address1: '',
      city: '',
      state: undefined,
      exp_date: '',
      postal_code: '',
      cvv: '',
      country: '',
      phone: '',
      month: '',
      year: '',
      cardNumber: '',
    });

    const hostedPaymentToken = ref();

    form.country = 'US';

    /** Credit Card logo */
    const cardBrand = ref('primaPay');

    /** Form validation rules (AntDesign) */
    const rules = reactive({
      firstName: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      lastName: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      email: [
        {
          type: 'email',
          message: 'The input is not valid E-mail!',
        },
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      phone: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      address1: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      city: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      state: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
        {
          validator: Validator.len('equals', 2),
          trigger: 'change',
          type: 'string',
        },
      ],
      country: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
        {
          validator: Validator.len('equals', 2),
          trigger: 'change',
          type: 'string',
        },
      ],
      postal_code: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
        {
          validator: Validator.len('equals', 5),
          trigger: 'change',
          type: 'string',
        },
      ],
      exp_date: [
        {
          validator: Validator.isRequired('Please pick a date'),
          trigger: 'change',
          type: 'object',
        },
      ],
    });

    const disableFields = ref(false);
    const disabledFieldEmail = ref(false);
    const disabledFieldPhone = ref(false);

    const paymentMethods = ref<any[]>([]);

    const savedPaymentMethod = ref<any>({});

    const loading = ref<boolean>(false);

    const visible = ref<boolean>(false);

    const errorValidateCardMessage = ref<string>('');
    const errorValidateCvvMessage = ref<string>('');
    const customerId = ref<string>('');

    /** Form validation result (AntDesign) */
    const { validateInfos, validate } = useForm(form, rules);

    /** Disable date in MonthPicker */
    const disabledDate = (current: any) => {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    };

    /** Get payment methods LexCharge */
    const getPaymentMethodsLexCharge = async () => {
      try {
        const result: any = await IntegrationsService.getPaymentMethodsLexCharge(
          String(props.contactId),
          String(props.primaPayAccountId),
        );

        if (result) {
          console.log(result);

          customerId.value = result[0] ? result[0].customer.id : null;

          form.email = result[0] ? result[0].customer.email : '';
          form.phone = result[0] ? result[0].customer.phone : '';

          disabledFieldEmail.value = true;
          disabledFieldPhone.value = true;

          paymentMethods.value = result.filter(
            (paymentMethod: any) => paymentMethod.method === 'card',
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    /** Update customer, save payment method LexCharge */
    const savePaymentMethod = async (customerId: string): Promise<any> => {
      try {
        const result = await IntegrationsService.savePaymentMethod(
          customerId,
          String(props.contactId),
          String(props.primaPayAccountId),
        );

        return Promise.resolve(result);
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      }
    };

    const setCardBrand = (message: any) => {
      return !message.cardType
        ? 'primaPay'
        : message.cardType === 'american_express'
        ? 'amex'
        : message.cardType === 'master'
        ? 'mastercard'
        : message.cardType === 'diners_club'
        ? 'dinersclub'
        : message.cardType;
    };

    let fattJs: any = null;

    const setLexChargeFields = (styleFields: any) => {
      // Init FattMerchant API
      fattJs = new (window as any).FattJs(hostedPaymentToken.value, {
        number: {
          id: 'fattjs-number',
          placeholder: '0000 0000 0000 0000',
          style: styleFields.card,
        },
        cvv: {
          id: 'fattjs-cvv',
          placeholder: '000',
          style: styleFields.cvv,
        },
      });

      fattJs
        .showCardForm()
        .then(() => {
          /** Get payment methods with contactId & mechantId */
          if (paymentMethods.value.length === 0 && props.primaPayAccountId)
            getPaymentMethodsLexCharge();
        })
        .catch((err: any) => {
          console.log('error init form ' + err);
        });

      fattJs.on('card_form_uncomplete', (message: any) => {
        // the customer hasn't quite finished filling in the fields
        // or the input in the fields are invalid

        /** Set card brand */
        cardBrand.value = setCardBrand(message);
      });

      fattJs.on('card_form_complete', (message: any) => {
        // the customer has finished filling in the fields, and they're valid!
        // Nice!

        /** Set card brand */
        cardBrand.value = setCardBrand(message);
      });
    };

    const styleLexChargeFields = {
      card:
        'width: 94%; height:90%; border-radius: 3px; border: 1px solid #ccc; padding: .5em .5em;',
      cvv:
        'width: 90%; height:90%; border-radius: 3px; border: 1px solid #ccc; padding: .5em .5em;',
    };

    /** Get hosted paymnt token for fields lex charge */
    const getHostedPaymentToken = async () => {
      try {
        const result: any = await IntegrationsService.getHostedPaymentToken(
          String(props.primaPayAccountId),
        );

        hostedPaymentToken.value = result.hostedPaymentToken;

        setLexChargeFields(styleLexChargeFields);
      } catch (error) {
        console.log(error);
      }
    };

    getHostedPaymentToken();

    /** Tokenize payment method lex charge */
    const tokenizePaymentMethod = async (): Promise<any> => {
      /** Validate form fields */
      validate();

      try {
        setLoading(true);
        const date = moment(form.exp_date);
        const [exp_month, exp_year] = [date.format('MM'), date.format('YYYY')];

        const extraDetails = {
          customer_id: customerId.value,
          first_name: form.firstName,
          last_name: form.lastName,
          month: exp_month,
          year: exp_year,
          phone: form.phone,
          email: form.email,
          address_1: form.address1,
          address_city: form.city,
          address_state: form.state,
          method: 'card',
          address_zip: String(form.postal_code),
          address_country: form.country,
        };

        try {
          const response = await fattJs.tokenize(extraDetails);

          savePaymentMethod(response.customer.id);

          return Promise.resolve(response);
        } catch (error) {
          errorValidateCardMessage.value = 'Credit card number invalid';

          setLexChargeFields({
            card: `${styleLexChargeFields.card} border-color: #f5222d;`,
            cvv: `${styleLexChargeFields.cvv} border-color: #f5222d;`,
          });

          /** Set card brand */
          cardBrand.value = 'primaPay';
          return Promise.reject(error);
        }
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      } finally {
        setLoading(false);
      }
    };

    /** Execute PrimaPay request */
    const primaPayRequest = async (): Promise<any> => {
      let result;

      if (savedPaymentMethod.value.id)
        result = JSON.parse(JSON.stringify(savedPaymentMethod.value));
      else result = await tokenizePaymentMethod();

      console.log(result);

      if (result && result !== null) {
        window.top.postMessage(result, process.env.VUE_APP_PRIMA_URL);

        return Promise.resolve(result);
      } else {
        message.success('PrimaPay response is invalid.');
        return Promise.reject('PrimaPay response is invalid.');
      }
    };

    const clearSavedPaymentMethod = () => {
      disableFields.value = false;
      form.firstName = '';
      form.lastName = '';
      form.state = undefined;
      form.exp_date = '';
      form.postal_code = '';
      form.address1 = '';
      form.city = '';
      form.country = 'US';
      savedPaymentMethod.value = 'none';
    };

    const setCustomMethodNumber = (cardLastFour: string) => {
      return `**** **** **** ${cardLastFour}`;
    };

    const setSavedPayment = async () => {
      if (savedPaymentMethod.value === 'none') {
        /** Set card brand */
        cardBrand.value = 'primaPay';
        clearSavedPaymentMethod();

        setLexChargeFields(styleLexChargeFields);
      } else if (savedPaymentMethod.value !== 'managePaymentMethods') {
        /** Set card brand */
        cardBrand.value = savedPaymentMethod.value.cardType;

        disableFields.value = true;
        form.firstName = savedPaymentMethod.value.personName.split(' ')[0];
        form.lastName = savedPaymentMethod.value.personName.split(' ')[1];
        form.state = savedPaymentMethod.value.addressState;
        moment.defaultFormat = 'DD.MM.YYYY';
        form.exp_date = String(
          moment(
            '08/' +
              savedPaymentMethod.value.cardExp.slice(0, 2) +
              savedPaymentMethod.value.cardExp.slice(2),
            moment.defaultFormat,
          ).toDate(),
        );
        form.cardNumber = setCustomMethodNumber(
          savedPaymentMethod.value.cardLastFour,
        );
        form.cvv = '***';
        form.address1 = savedPaymentMethod.value.address1;
        form.city = savedPaymentMethod.value.addressCity;
        form.postal_code = savedPaymentMethod.value.addressZip;
        form.country = savedPaymentMethod.value.addressCountry;
      } else setLexChargeFields(styleLexChargeFields);
    };

    const showModal = () => {
      visible.value = true;
    };

    const handleClose = () => {
      visible.value = false;
    };

    const deletePaymentMethod = async (paymentMethod: any) => {
      loading.value = true;
      const data = await IntegrationsService.deletePaymentMethod(
        Number(props.contactId),
        paymentMethod.id,
      );

      if (data && data !== null) {
        loading.value = false;
      }
    };

    const filterOption = (input: string, option: any) => {
      return option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    return {
      form,
      cardBrand,
      validateInfos,
      disabledDate,
      paymentMethods,
      savedPaymentMethod,
      setSavedPayment,
      disableFields,
      disabledFieldEmail,
      disabledFieldPhone,
      deletePaymentMethod,
      showModal,
      handleClose,
      visible,
      states,
      filterOption,
      errorValidateCardMessage,
      errorValidateCvvMessage,
      setCustomMethodNumber,
      primaPayRequest,
      customerId,
      validate,
    };
  },
});
</script>

<style lang="scss" scoped>
.card-brand {
  position: absolute;
  right: 8px;
  height: 24px;
  top: 3px;
  pointer-events: none;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}
</style>
