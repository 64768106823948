<template>
  <AForm layout="vertical">
    <ARow :gutter="12">
      <ACol :span="24">
        <h3
          v-if="primaPayTrustAccountId"
          style="color: rgb(0, 117, 213); margin-bottom: 35px"
        >
          Message for indicate this method is associated to trust account and it
          will pay to operating account.

          <hr />
        </h3>

        <AFormItem v-else label="Saved Banks" name="savedBanks">
          <ASelect
            v-model:value="savedPaymentMethod"
            @change="setSavedPayment"
            :disabled="paymentMethods.length === 0"
          >
            <ASelectOption value="managePaymentMethods" @click="showModal">
              Manage Payment Methods
            </ASelectOption>
            <ASelectOption value="none"> None </ASelectOption>
            <ASelectOption
              v-for="(paymentMethod, paymentMethodIndex) in paymentMethods"
              :key="paymentMethodIndex"
              :value="paymentMethod"
            >
              {{ paymentMethod.nickname }}
            </ASelectOption>
          </ASelect>
        </AFormItem>
      </ACol>

      <ACol :span="8">
        <AFormItem
          label="Account Holder Type"
          name="account_holder_type"
          v-bind="validateInfos['account_holder_type']"
        >
          <ASelect
            v-model:value="form.account_holder_type"
            :disabled="disableFields"
          >
            <ASelect-option value="business"> Business </ASelect-option>
            <ASelect-option value="personal"> Personal </ASelect-option>
          </ASelect>
        </AFormItem>
      </ACol>

      <ACol :span="8">
        <AFormItem
          label="First Name"
          name="firstName"
          v-bind="validateInfos['firstName']"
        >
          <AInput
            v-model:value="form.firstName"
            placeholder="First Name"
            :disabled="disableFields || disabledFieldFirstName"
          />
        </AFormItem>
      </ACol>

      <ACol :span="8">
        <AFormItem
          label="Last name"
          name="lastName"
          v-bind="validateInfos['lastName']"
        >
          <AInput
            v-model:value="form.lastName"
            placeholder="Last Name"
            :disabled="disableFields || disabledFieldLastName"
          />
        </AFormItem>
      </ACol>

      <ACol :span="8">
        <AFormItem label="Email" name="email" v-bind="validateInfos['email']">
          <AInput
            v-model:value="form.email"
            placeholder="Email"
            :disabled="disableFields || disabledFieldEmail"
          />
        </AFormItem>
      </ACol>

      <ACol :span="8">
        <AFormItem label="Phone" name="phone" v-bind="validateInfos['phone']">
          <input
            type="text"
            v-maska="'(###) ### ####'"
            v-model="form.phone"
            class="ant-input"
            placeholder="Phone"
            :disabled="disableFields || disabledFieldPhone"
          />
        </AFormItem>
      </ACol>

      <ACol :span="8">
        <AFormItem
          label="Bank Account Name"
          name="bank_account_name"
          v-bind="validateInfos['bank_account_name']"
        >
          <AInput
            v-model:value="form.bank_account_name"
            placeholder="Bank Account Name"
            :disabled="disableFields || disabledFieldBankAccountName"
          />
        </AFormItem>
      </ACol>

      <ACol :xs="{ span: 12 }" :sm="{ span: 12 }">
        <AFormItem
          label="Bank Account Number"
          name="bank_account_number"
          v-bind="validateInfos['bank_account_number']"
        >
          <AInput
            v-model:value="form.bank_account_number"
            placeholder="Bank Account Number"
            :disabled="disableFields"
          />
        </AFormItem>
      </ACol>

      <ACol :xs="{ span: 12 }" :sm="{ span: 12 }">
        <AFormItem
          label="Routing Number"
          name="routing_number"
          v-bind="validateInfos['routing_number']"
        >
          <AInput
            v-model:value="form.routing_number"
            placeholder="Routing Number"
            :disabled="disableFields"
          />
        </AFormItem>
      </ACol>

      <ACol :span="8">
        <AFormItem
          label="Account Type"
          name="account_type"
          v-bind="validateInfos['account_type']"
        >
          <ASelect v-model:value="form.account_type" :disabled="disableFields">
            <ASelect-option value="savings"> Savings </ASelect-option>
            <ASelect-option value="checking"> Checking </ASelect-option>
          </ASelect>
        </AFormItem>
      </ACol>

      <ACol :span="8">
        <AFormItem
          label="Address"
          name="address"
          v-bind="validateInfos['address1']"
        >
          <AInput
            v-model:value="form.address1"
            placeholder="Address"
            :disabled="disableFields"
          />
        </AFormItem>
      </ACol>

      <ACol :xs="{ span: 8 }" :sm="{ span: 8 }">
        <AFormItem label="City" name="city" v-bind="validateInfos['city']">
          <AInput
            v-model:value="form.city"
            placeholder="City"
            style="width: 100%"
            :disabled="disableFields"
          />
        </AFormItem>
      </ACol>

      <ACol :xs="{ span: 8 }" :sm="{ span: 8 }">
        <AFormItem
          label="State Code"
          name="state"
          v-bind="validateInfos['state']"
        >
          <ASelect
            v-model:value="form.state"
            show-search
            :disabled="disableFields"
            placeholder="Choose state code"
            :filter-option="filterOption"
          >
            <ASelectOption
              v-for="(state, stateIndex) in states"
              :key="stateIndex"
              :value="state"
            >
              {{ state }}
            </ASelectOption>
          </ASelect>
        </AFormItem>
      </ACol>

      <ACol :xs="{ span: 8 }" :sm="{ span: 8 }">
        <AFormItem
          label="Postal Code"
          name="postal_code"
          v-bind="validateInfos['postal_code']"
        >
          <AInput
            v-model:value="form.postal_code"
            type="number"
            :min="0"
            placeholder="Postal Code"
            style="width: 100%"
            :disabled="disableFields"
          />
        </AFormItem>
      </ACol>

      <ACol :xs="{ span: 8 }" :sm="{ span: 8 }">
        <AFormItem
          label="Country"
          name="country"
          v-bind="validateInfos['country']"
        >
          <AInput
            v-model:value="form.country"
            placeholder="Country"
            style="width: 100%"
            :disabled="true"
          />
        </AFormItem>
      </ACol>
    </ARow>
  </AForm>

  <slot :validateFn="validate" :primaPayRequestFn="primaPayRequest" />
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue';
import { useForm } from '@ant-design-vue/use';

import { PaymentForm } from '@/types/payment-form.type';
import { Validator } from '@/utils/ant-custom-validators';
import { useGlobalProps } from '@/composables';
import { message } from 'ant-design-vue';
import { IntegrationsService } from '@/services';

export default defineComponent({
  props: {
    contactId: {
      type: String,
    },
    primaPayAccountId: {
      type: String,
    },
    primaPayTrustAccountId: {
      type: String,
    },
  },
  setup(props) {
    const { setLoading, states } = useGlobalProps();

    const savedPaymentMethod = ref<any>({});
    const disableFields = ref(false);
    const disabledFieldEmail = ref(false);
    const disabledFieldPhone = ref(false);
    const disabledFieldFirstName = ref(false);
    const disabledFieldLastName = ref(false);
    const disabledFieldBankAccountName = ref(false);
    const paymentMethods = ref<any[]>([]);
    const hostedPaymentToken = ref();
    const customerId = ref<string>('');
    const bankTrustAccountNumber = ref<string>('');
    const bankTrustRoutingNumber = ref<string>('');

    const form = reactive<PaymentForm['e_check']>({
      account_holder_type: 'business',
      name: '',
      business_name: '',
      firstName: '',
      lastName: '',
      bank_account_number: null,
      routing_number: null,
      account_type: 'checking',
      email: '',
      address1: '',
      city: '',
      state: undefined,
      postal_code: null,
      country: '',
      bank_account_name: '',
      phone: '',
    });

    form.country = 'USA';

    const rules = reactive({
      firstName: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      lastName: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      email: [
        {
          type: 'email',
          message: 'The input is not valid E-mail!',
        },
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      phone: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      address1: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      city: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      state: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      postal_code: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
        {
          validator: Validator.len('equals', 5),
          trigger: 'change',
          type: 'string',
        },
      ],
      country: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      bank_account_name: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      bank_account_number: [
        {
          validator: Validator.len('min', 8),
          trigger: 'change',
        },
        {
          validator: Validator.len('max', 17),
          trigger: 'change',
        },
        {
          validator: Validator.lastFourNumbers(bankTrustAccountNumber),
          trigger: 'change',
        },
      ],
      routing_number: [
        {
          validator: Validator.len('min', 5),
          trigger: 'change',
        },
        {
          validator: Validator.len('max', 9),
          trigger: 'change',
        },
        {
          validator: Validator.lastFourNumbers(bankTrustRoutingNumber),
          trigger: 'change',
        },
      ],
    });

    /** Form validation result (AntDesign) */
    const { validateInfos, validate } = useForm(form, rules);

    const clearSavedPaymentMethod = () => {
      disableFields.value = false;
      form.firstName = '';
      form.lastName = '';
      form.state = undefined;
      form.postal_code = '';
      form.address1 = '';
      form.city = '';
      form.country = 'US';
      form.account_holder_type = 'business';
      form.bank_account_number = '';
      form.routing_number = '';
      form.bank_account_name = '';
      savedPaymentMethod.value = 'none';
    };

    const setCustomMethodNumber = (cardLastFour: string) => {
      return `******${cardLastFour}`;
    };

    const setSavedPayment = async () => {
      console.log(savedPaymentMethod.value);
      if (savedPaymentMethod.value === 'none') {
        clearSavedPaymentMethod();
      } else if (savedPaymentMethod.value !== 'managePaymentMethods') {
        disableFields.value = true;
        form.firstName = savedPaymentMethod.value.personName.split(' ')[0];
        form.lastName = savedPaymentMethod.value.personName.split(' ')[1];
        form.state = savedPaymentMethod.value.addressState;
        form.bank_account_number = setCustomMethodNumber(
          savedPaymentMethod.value.cardLastFour,
        );
        form.bank_account_name = savedPaymentMethod.value.bankName;
        form.routing_number = '*********';
        form.address1 = savedPaymentMethod.value.address1;
        form.city = savedPaymentMethod.value.addressCity;
        form.postal_code = savedPaymentMethod.value.addressZip;
        form.country = savedPaymentMethod.value.addressCountry;
        form.account_holder_type = savedPaymentMethod.value.bankHolderType;
      }
    };

    /** Get payment methods LexCharge for lex charge trust account or contactId */
    const getPaymentMethodsLexCharge = async () => {
      try {
        const result: any = await IntegrationsService.getPaymentMethodsLexCharge(
          props.primaPayTrustAccountId
            ? props.primaPayTrustAccountId
            : String(props.contactId),
          String(props.primaPayAccountId),
        );

        if (result) {
          console.log(result);

          customerId.value = result[0] ? result[0].customer.id : null;

          form.email = result[0] ? result[0].customer.email : '';
          form.phone = result[0] ? result[0].customer.phone : '';

          disabledFieldEmail.value = true;
          disabledFieldPhone.value = true;

          /** If primaPayTrustAccountId set method for default on fields information */
          if (props.primaPayTrustAccountId) {
            bankTrustAccountNumber.value = '';
            bankTrustRoutingNumber.value = '';

            savedPaymentMethod.value = result[0];

            setSavedPayment();
          } else {
            paymentMethods.value = result.filter(
              (paymentMethod: any) => paymentMethod.method === 'bank',
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    /** Update customer, save payment method LexCharge with lex charge trust account
     * or contactId
     */
    const savePaymentMethod = async (customerId: string): Promise<any> => {
      try {
        const result = await IntegrationsService.savePaymentMethod(
          customerId,
          props.primaPayTrustAccountId
            ? props.primaPayTrustAccountId
            : String(props.contactId),
          String(props.primaPayAccountId),
        );

        console.log(result);

        return Promise.resolve(result);
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      }
    };

    let fattJs: any = null;

    const setInitLexCharge = () => {
      // Init FattMerchant API
      fattJs = new (window as any).FattJs(hostedPaymentToken.value, {});

      if (paymentMethods.value.length === 0 && props.primaPayAccountId) {
        getPaymentMethodsLexCharge();
      }
    };

    /** Get hosted paymnt token for fields lex charge */
    const getHostedPaymentToken = async () => {
      try {
        const result: any = await IntegrationsService.getHostedPaymentToken(
          String(props.primaPayAccountId),
        );

        console.log(result);

        hostedPaymentToken.value = result.hostedPaymentToken;

        setInitLexCharge();
      } catch (error) {
        console.log(error);
      }
    };

    getHostedPaymentToken();

    const getTrustAccountDetails = async (merchantId: string) => {
      try {
        const result: any = await IntegrationsService.getDetailsPrimaPayAccount(
          merchantId,
        );

        console.log('trut', result);

        form.firstName = result.bankAccountOwnerName.split(' ')[0];
        form.lastName = result.bankAccountOwnerName.split(' ')[1];
        form.bank_account_name = result.accountName;

        if (form.firstName) disabledFieldFirstName.value = true;
        if (form.lastName) disabledFieldLastName.value = true;
        if (form.bank_account_name) disabledFieldBankAccountName.value = true;

        bankTrustAccountNumber.value = result.bankAccountNumber;
        bankTrustRoutingNumber.value = result.bankRoutingNumber;
      } catch (error) {
        console.error(error);
      }
    };

    /** If primaPayTrustAccountId get details for validate */
    if (props.primaPayTrustAccountId)
      getTrustAccountDetails(props.primaPayTrustAccountId);

    /** Tokenize payment method lex charge */
    const tokenizePaymentMethod = async (): Promise<any> => {
      /** Validate form fields */
      validate();

      try {
        setLoading(true);

        const extraDetails = {
          customer_id: customerId.value,
          first_name: form.firstName,
          last_name: form.lastName,
          person_name: `${form.firstName} ${form.lastName}`,
          method: 'bank',
          bank_type: form.account_type,
          bank_account: form.bank_account_number,
          bank_routing: form.routing_number,
          bank_name: form.bank_account_name,
          bank_holder_type: form.account_holder_type,
          phone: form.phone,
          email: form.email,
          address_1: form.address1,
          address_city: form.city,
          address_state: form.state,
          address_zip: String(form.postal_code),
          address_country: form.country,
        };

        try {
          const response = await fattJs.tokenize(extraDetails);

          console.log('payment method object:', response);
          console.log('customer object:', response.customer);

          savePaymentMethod(response.customer.id);

          return Promise.resolve(response);
        } catch (error) {
          console.log('unsuccessful tokenization:', error);
          return Promise.reject(error);
        }
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      } finally {
        setLoading(false);
      }
    };

    /** Execute PrimaPay request */
    const primaPayRequest = async (): Promise<any> => {
      let result;

      if (savedPaymentMethod.value.id)
        result = JSON.parse(JSON.stringify(savedPaymentMethod.value));
      else result = await tokenizePaymentMethod();
      console.log(result);
      if (result && result !== null) {
        window.top.postMessage(result, process.env.VUE_APP_PRIMA_URL);

        return Promise.resolve(result);
      } else {
        message.success('PrimaPay response is invalid.');
        return Promise.reject('PrimaPay response is invalid.');
      }
    };

    const filterOption = (input: string, option: any) => {
      return option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    return {
      form,
      savedPaymentMethod,
      disableFields,
      disabledFieldEmail,
      disabledFieldPhone,
      disabledFieldFirstName,
      disabledFieldLastName,
      disabledFieldBankAccountName,
      paymentMethods,
      validateInfos,
      states,
      filterOption,
      primaPayRequest,
      setSavedPayment,
      validate,
    };
  },
});
</script>
